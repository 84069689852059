import { nextTick,defineComponent, ref, provide, reactive, toRefs, getCurrentInstance, onMounted, onBeforeUnmount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import PageLoading from '@/components/common/PageLoading.vue';
import HeaderVR from '@/components/layout/header_vr.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import Swiper from '@/components/layout/Swiper.vue';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';
import vueEsign from 'vue-esign'
import QRCode from 'qrcode'
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	components: {
		HeaderVR,
		Menu,
	    Footer,
	    PageLoading,
	    Swiper,
	    SideComponent,
	    vueEsign,
	    Loading,
	},
	props: {

	},
	setup() {
		provide('logoColor', 'black');
		let {ctx, proxy} = getCurrentInstance();
		const router = useRouter();
	    const route = useRoute();
	    const id = route.query.id;
		const data = reactive({
			isLoading:true,
			showHeader: true,
			loginStyle: "min-width:141px;",
			mobileIconStyle: "color:rgba(223, 58, 8, 1);",
			showListIcon: true,
			imageSrc: '',
			//定义一个imageNew变量来接收裁剪之后的图片
			imageNew: '',		
      		cropperOption: {
		        img: "",
		        size: 1,
		        full: false,
		        outputType: "png",
		        canMove: false,
		        fixedBox: false,
		        original: false,
		        canMoveBox: true,
		        autoCrop: true,
		        // 只有自动截图开启 宽度高度才生效
		        autoCropWidth: 160,
		        autoCropHeight: 150,
		        centerBox: true,
		        high: true,
		        max: 99999,
		        fixed: true,
		        fixedNumber: [1, 2],
		    },
		    vrlist:[],
		    banner:'',
		    id:0,
		    detail:{},
		    lineWidth: 6,
		    lineColor: '#000000',
		    bgColor: 'rgb(219,219,219)',
		    resultImg: '',
		    isCrop: false,
		    pickedItem: 0,
		    packList: [],
		    picPrevileges:[],
		    singleVideoPrevileges:[],
		    packVideoPrevileges:[],
		    
		    keys:[],
		    orderInfo:{},
		    showQrcode: false,
		    qrCodeImgUrl:'',
		    payType:[
		    	{
		    		id: 2,
		    		title: proxy.$t('package.wechatPay'),
		    	},
		    	{
		    		id: 3,
		    		title: proxy.$t('package.alipay'),
		    	}
		    ],
		    checkedPaytypeId: 2,
		    payTypeName: proxy.$t('package.weixin'),
		    isListLoading: true,
		    isPicLoading: true,
		    isVideoLoading: true,
		});
		
		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);
		provide('menuTextStyle', {color:'#fff'});
		provide('showHeader',false);
		// provide('swiperBoxStyle', {height:'800px'});
		
		data.id = id;
		const getImageUrl = (name) => {
		    return proxy.$utils.getImageUrl(name);
		};

		const store = useStore();
		const getDetail=(id=0)=>{
			store.dispatch("common/AdNewsDetail",{
				id: id
	        }).then((res) => {
	        	data.detail = res;
	        })
		}
		
		const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}
		const PackList = (val) => {
			data.isListLoading = true;
			store.dispatch("common/PackList",{
				
	        }).then((res) => {
	        	data.packList = res;
	        	data.isListLoading = false;
	        }).catch(err=>{
	        	data.isListLoading = false;
	        })
		}
		const PackPrevileges = (type=0) => {
			data.isPicLoading = true;
			store.dispatch("common/PackPrevileges",{
				type: type

	        }).then((res) => {
	        	console.log(res,"res");
	        	// data.keys = Object.keys(res);
	        	if(type==1){
	        		data.picPrevileges = res;
	        	}else if(type==2){
	        		data.singleVideoPrevileges = res;
	        	}else if(type==3){
	        		data.packVideoPrevileges = res;
	        	}
	        	data.isPicLoading = false;
	        	bus.emit('showFooter',true);
	        	// console.log(data.previleges,"data.previleges")
	        }).catch(err=>{
	        	data.isPicLoading = false;
	        })
		}

		const order = (package_id,checkedPaytypeId) => {
			store.dispatch("user/RechargeOrder",{package_id: package_id}).then((res)=>{				
				
				// console.log(res,"RechargeOrder");
				data.orderInfo = res;
				chargePay(res.id,checkedPaytypeId);

			}).catch(error => {
				bus.emit('showLoginDialog', true);
		        bus.emit('showLoginDialogMobile', false)
		        bus.emit('showLoginDialogQrcode', false)
		        bus.emit('showLoginDialogLogin', true)
			  	ElMessage({
			        message: error.message,
			        type: "error",
			    });
			});
		}
		const chargePay = (oid,checkedPaytypeId) => {
			store.dispatch("user/ChargePay",{
					id: oid,
					ptype: checkedPaytypeId,
				}).then((res)=>{
				console.log(res,"ChargePay-2");
				data.showQrcode = true;
				if(checkedPaytypeId == 2){
					if(res.return_msg=='OK'&&res.return_code=='SUCCESS'&&res.result_code=='SUCCESS'){
						console.log("weixin");
						// 使用toDataURL方法 返回的是promise对象 通过.then取值
					    let img = QRCode.toDataURL(res.code_url).then(t => {
					      data.qrCodeImgUrl = t;
					      data.showRecharge = false;
					    })
					    loopOrderStatus(oid)
					}
				} else if(checkedPaytypeId == 3){
					if(res.msg=='Success'){
						console.log("Alipay");
						let img = QRCode.toDataURL(res.code_url).then(t => {
					      data.qrCodeImgUrl = t;
					      data.showRecharge = false;
					    })
					    loopOrderStatus(oid)
					}
				}
				

			}).catch(error => {
			  	ElMessage({
			        message: "网络异常，请重试",
			        type: "error",
			    });
			});
		}
		const loopOrderStatus=(oid)=>{
			let timerId = setInterval(function(){
				store.dispatch("user/BalanceRecordInfo",{id:oid}).then((res)=>{
					// console.log(res,"loop");
					if(res.status == 1){
						
						clearInterval(timerId);
						data.showQrcode = false;
						ElMessage({
					        message: "Success",
					        type: "success",
					        duration: 10000,
					        offset:16,
					    });
					}
				});
			},2000);
		}

	    onMounted(async()=>{
	    	data.banner = store.state.common.siteInfo.ad_sliders;	    	
	    	await nextTick(()=>{
	    		PackList()
	    		PackPrevileges(1)
	    		PackPrevileges(2)
	    		PackPrevileges(3)
	    	});
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 500);
		    console.log("页面数据更新完毕了")
        })

		// onMounted(() => {
			// console.log(proxy.$router === router) // true
	  //     	console.log(route) // {path, params, query...}

		    // // 访问组件的 DOM 元素
		    //   element.value = ctx.$el

		    //   // 发送网络请求
		    //   axios.get('/api/data').then(response => {
		    //     ctx.data = response.data
		    //   })

		    //   // 监听数据变化
		    //   ctx.$watch('data', () => {
		    //     console.log('data changed')
		    //   })

		    //   // 在组件销毁时取消异步操作
		    //   ctx.$once('hook:beforeUnmount', () => {
		    //     cancelAsyncTask()
		    //   })
		    
		// });

		const refData = toRefs(data);

		return {
			...refData,
			getImageUrl,
			viewBox,
			scroll,
			getDetail,
			PackList,
			PackPrevileges,
			order,
			chargePay,

		}
	},
	created() {

	},
	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},
	watch:{
		checkedPaytypeId:{
			handler(value) {
				if(value==2){
					this.payTypeName = this.$t('package.weixin');
				}else if(value==3){
					this.payTypeName = this.$t('package.ali');
				}
				
			}
		}
	},
	methods: {
		init() {
			// this.imageSrc = ref(this.getImageUrl('img/index/i.jpg'));
		},
		submit() {
			// console.log('pickedItem',this.pickedItem);
			// console.log("this.$route",this.$route)
			const isAuthenticated = this.$cookies.getAuthKey();
			if(isAuthenticated){
				if(!this.pickedItem){
					ElMessage({
					    message: this.$t('package.select'),
					    grouping: true,
					    type: 'warning',
					})
					return false;
				}else{
					bus.emit('needRefresh', true)
					this.order(this.pickedItem, this.checkedPaytypeId);
				}
			}else{
				sessionStorage.setItem('redirectPath', this.$route.fullPath);
				bus.emit('showLoginDialog', true);
				bus.emit('showLoginDialogMobile', false)
        		bus.emit('showLoginDialogQrcode', true)
				// ElMessage({
				//     message: '请选择套餐',
				//     grouping: true,
				//     type: 'warning',
				// })
				return false;
			}
			
		},
		realTime(data) {
			// console.log(data,"data");
  			var previews = data;
  			this.previews = data
  		},
  		tovr(url) {
	        // window.location.href = url;
	        window.open(url,"_blank")
	    },
	    closeQrcode() {
			this.showQrcode = false;
		},
	    handleReset () {
		    this.$refs.esign.reset()
		},
		handleGenerate () {
		    this.$refs.esign.generate().then(res => {
		    	// console.log(res,'sign');
		      this.resultImg = res
		    }).catch(err => {
		      console.log(err,'sign err') // 画布没有签字时会执行这里 'Not Signned'
		    })
		}
	},
})