// import Search from '@/components/Search.vue'
import { nextTick, onMounted, ref, provide, reactive, toRefs, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore, createNamespacedHelpers } from "vuex";
import PageLoading from '@/components/common/PageLoading.vue';
import Loading from '@/components/common/Loading.vue';
import Swiper from '@/components/layout/Swiper.vue';
import SearchBarPic from '@/components/layout/SearchBarPic.vue';
import Header2 from '@/components/layout/header2.vue';
import Footer from '@/components/layout/footer.vue';
import Menu from '@/components/layout/menu.vue';
import axios from 'axios';
import bus from 'vue3-eventbus'
import SideComponent from '@/components/common/SideComponent.vue';

export default {
	name: 'photo_grapher',
	components: {
		Header2,
		Menu,
	    Swiper,
	    SearchBarPic,
	    Footer,
	    PageLoading,
	    SideComponent,
	    Loading,
	},
	setup(){	    
	    // 写法一 用了reactive，就不需要写ref
	    let {ctx, proxy} = getCurrentInstance();
      	const router = useRouter();
      	const route = useRoute();
      	// console.log(route,"route");
      	provide('logoColor', 'white');
	    const data = reactive({
	    	isLoading:true,
	    	active_menuid: 0,
			activeClass: 'active',
			isMobile: false,
      		show_menu: false,
      		active_tagid: 'pic',
      		ispic: true,
      		banners:[
      			
      		],
      		
      		picList: [],
      		videoList: [],
      		pagination: {},
      		currentTag: 0,
      		catid: 0,
      		page: 1,
      		nomore: true,
      		keyword: '',
      		tag: 1,
      		tags:[
      			{
      				id: 1,
      				name: "征集作品展示"
      			},
      			{
      				id: 2,
      				name: "征集启示"
      			},
      		],
      		dialogTagId: 1,
      		dialogTags:[
      			{
      				id: 1,
      				name: "图片"
      			},
      			{
      				id: 2,
      				name: "视频"
      			},
      		],
      		showList: true,
      		showRevelation: false,
      		isPicLoading: true,
      		isVideoLoading: true,
      		uploadVisible: false,
      		picList:[
      			
      			
      		],
	    });
        const store = useStore();
        
        
        const fetchList = (page=1) =>{

	        store.dispatch("common/GetSubjectList",{
	        	page:page
	        }).then((res) => {
	        	console.log(res,"subject-list");
	        	let list = res.data;
	        	
	            if(list.length > 0){
	            	if(page == 1){
	            		data.picList = list;
	            	}else{
	            		data.picList = data.picList.concat(list);
	            	}
					
					if(list.length < res.pagination.pageSize){
						data.nomore = true;
					}else{
						data.nomore = false;
					}

				}else{
					data.nomore = true;
				}        
	        	// data.pagination = res.pagination;
	        	data.page += 1;
	        	data.isPicLoading = false;
	        	bus.emit('showFooter',true);
	        })

        }
        
        const viewBox = ref(null);
        const scroll = (val) => {
			if(val == 'top'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		       
			}else if(val == 'bottom'){
				
				viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "end", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		        	
		        	
		        
			}
		}

        onMounted(async()=>{
        	store.dispatch("common/fetchSiteInfo").then((res) => {
	              data.banners = res.topic_sliders 
	              bus.emit('isLoading',false);
	        }).catch(err => {
	              console.error(err)
	        })
	        fetchList();
        	// fetchGrapher(data.keyword,data.catid,0,data.page);
        	await nextTick()
        	setTimeout(() => {
		        data.isLoading = false;
		        viewBox.value.scrollIntoView({
			        behavior: "smooth", // 定义过渡动画 instant立刻跳过去 smooth平滑过渡过去
			        block: "start", // 定义垂直滚动方向的对齐 start顶部（尽可能）  center中间（尽可能）  end（底部）
			        inline: "center", // 定义水平滚动方向的对齐
			    });
		    }, 600);
		    console.log("页面数据更新完毕了")
        })   

		const showMenus = ref(proxy.$menus);
		provide('showMenu', showMenus);

		const handleSizeChange = (val) => {
		  console.log(`${val} items per page`)
		}
		const handleCurrentChange = (val) => {
		  console.log(`current page: ${val}`)
		  data.page = val;
		}

        const refData = toRefs(data);
	    return {
	        ...refData,
	        handleSizeChange,
	        handleCurrentChange,
	        fetchList,
	        viewBox,
	        scroll,
	    }
	},
	computed: {

	},

	mounted() {
		this.$nextTick(() => {
			this.init();
		});
	},

	methods: {
		init() {

		},	
		toActive(id) {
			// this.$router.push({ name:'subject_detail', query: {id: id} });
			let href = this.$router.resolve({ //使用resolve
		      name:'subject_detail',    //这里是跳转页面的name
		      path: '/subject_detail',
		      query:{ id: id },
		    })
		    window.open(href.href, '_blank')
		},
		switchTag(item) {
			this.tag = item.id;
			if(item.id == 1){
				this.showList = true;
      			this.showRevelation = false;
			}else if(item.id == 2){
				this.showList = false;
      			this.showRevelation = true;
			}
		},
		async activeStyle(item) {		
			if(item.ratio_w == null || item.ratio_h == null) {
				
			    let size = await this.$utils.getImageSize(item.preview);
			    console.log(size,"size");
			    item.ratio_w = size.width;
			    item.ratio_h = size.height;

			}

			let object = {};
			object.width = item.ratio_w*180/item.ratio_h+'px';

			return object;
		},
		uploadThumb(event){
	    	let that = this;
        	event.preventDefault();
            let file = event.currentTarget.files[0];
            console.log(file,"file");
            var formData = new FormData();
    		formData.append("file", file);
        	this.$store.dispatch('user/GrapherWorkerUpload', formData).then((res)=>{	            		
            	console.log("upload-1",res);
            	// fileInfo.url = res.url;
            	// that.subjectForm.thumb = res.url;
            }).catch(err=>{
				ElMessage({
			        message: err.message,
			        type: "warning",
			    });
			})
	    },
		loadMore() {
			this.type = 'page';
			// this.fetchList(this.keyword,this.catid, this.currentTag,this.page);
			this.fetchList(this.page);
		},
	    toDetail(id){
	      	// this.$router.push({ path:'/photo_grapher_detail', query:{ id: id } });
	    },
	    toUpload() {
	    	this.$router.push( {name: 'active_detail'} );
	    },
	    tagStyle(item) {
	    	if(this.tag == item.id){
	    		return ['active'];
	    	}
	    	return '';
	    },
	},
	beforeDestory() {

	},
	destoryed() {

	}
}